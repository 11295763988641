@keyframes fadeRight {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Animasi dengan delay 2 detik */
.fade-right {
  animation: fadeRight 1s ease-in-out forwards;
}

.fade-left {
  animation: fadeLeft 1s ease-in-out forwards;
}
